import React from "react";
import About from "../../Components/About/About";
import Clients from "../../Components/Clients/Clients";
import Copyright from "../../Components/Copyright/Copyright";
import Footer from "../../Components/Footer/Footer";
import Landing from "../../Components/Landing/Landing";
import Projects from "../../Components/Projects/Projects";
import Services from "../../Components/Services/Services";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <Landing />
      <Services />
      <About />
      <Projects />
      <Clients />
      <Footer />
      <Copyright />
    </div>
  );
};

export default Home;
