import React from "react";
import "./Services.css";
import Container from "./../Container/Container";
import SectionHeading from "../SectionHeading/SectionHeading";
import ServiceCard from "../ServiceCard/ServiceCard";
import ThemeHeader from "../ThemeHeader/ThemeHeader";
import service1 from "./../../assets/service1.png";
import service2 from "./../../assets/service2.png";
import service3 from "./../../assets/service3.png";
import service4 from "./../../assets/service4.png";

const services = [
  {
    heading: "Electrical Works",
    content:
      "We provide building power systems, UPS systems, lighting modelling, power and cogeneration plants and ancillary services",
    img: service1,
  },
  {
    heading: "Mechanical Works",
    content:
      "We provide HVAC and ventilation engineering, central utilities engineering, fire and life safety engineering and ancillary mechanical services",
    img: service2,
  },
  {
    heading: "Bio-medical Works",
    content:
      "We help hospitals and health systems by providing comprehensive, high-quality service for your surgical equipment and instruments",
    img: service3,
  },
  {
    heading: "Project Management",
    content:
      "We assist organizations in doing the right projects—those with strategic alignment that make the best use of your time, talent, and budget—and doing them right",
    img: service4,
  },
];

const Services = () => {
  return (
    <div className="services" id="services">
      <Container>
        <div className="service-header">
          <SectionHeading>Our Best Providings</SectionHeading>
          <ThemeHeader
            style={{ marginBottom: "1em" }}
            fontWeight="bold"
            fontSize={48}
          >
            Services We Are Offering
          </ThemeHeader>
        </div>
        <div className="service-list">
          {services.map((service) => (
            <ServiceCard {...service} key={service.heading} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Services;
