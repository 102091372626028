import firebase from 'firebase/app'
// import "firebase/auth";
import "firebase/firestore";
// import "firebase/functions";
// import "firebase/storage"

var firebaseConfig = {
    apiKey: "AIzaSyBxwIcnrui4dwzGZnSwwq3PBn5152ys1-M",
    authDomain: "handw-be8ce.firebaseapp.com",
    projectId: "handw-be8ce",
    storageBucket: "handw-be8ce.appspot.com",
    messagingSenderId: "1066474135332",
    appId: "1:1066474135332:web:aafacdb59a5c6e2c905db1"
  };

firebase.initializeApp(firebaseConfig);

// export var auth = firebase.auth();
export var firestore = firebase.firestore();
// export var cloudFunctions = firebase.functions();
// export var storage = firebase.storage();
// export var serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

// export var googleProvider = new firebase.auth.GoogleAuthProvider();
// export var facebookProvider = new firebase.auth.FacebookAuthProvider();

export default firebase;

