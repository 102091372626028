import React from 'react'
import Button from '../Button/Button'
import "./ThemeButton.css"

const ThemeButton = ({children, ...restProps}) => {
    return (
        <Button {...restProps} fontWeight="semi-bold" className="theme-button" backgroundColor="var(--color-primary)" color="var(--color-secondary)" >
            {children}
        </Button>
    )
}

export default ThemeButton
