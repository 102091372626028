import React from "react";
import "./Footer.css";
import Container from "./../Container/Container";
import Paragraph from "../Paragraph/Paragraph";
import phone from "./../../assets/phone.png";
import location from "./../../assets/location.png";
import mail from "./../../assets/mail.png";
import facebook from "./../../assets/facebook.png";
import twitter from "./../../assets/twitter.png";
import ThemeHeader from "../ThemeHeader/ThemeHeader";

const ContactItem = ({ img, children }) => (
  <div className="contact-item">
    <img className="contact-item-icon" src={img} />
    <Paragraph fontWeight="bold">{children}</Paragraph>
  </div>
);

const Footer = () => {
  return (
    <Container>
      <div className="footer" id="footer">
        <div className="footer-left">
          <ThemeHeader
            style={{ marginBottom: "1em" }}
            fontWeight="bold"
            fontSize={48}
          >
            H & W Solutions
          </ThemeHeader>
          <Paragraph className="footer-para" style={{ marginBottom: "1em" }}>
            We provide solutions for a wide range of Engineering Fields. Our
            solution ranges from designing electrical system for a small home to
            the construction of power generation plants. Having a diversified
            team of Engineers, our modern solutions get the valuable clients up
            to the speed.
          </Paragraph>
          <Paragraph className="footer-para">
            H&W Solutions is the only firm in Pakistan which is providing the
            complete engineering solutions for a wide range of engineering
            fields.
          </Paragraph>
        </div>
        <div className="footer-right">
          <ThemeHeader
            style={{ marginBottom: "1em" }}
            fontWeight="bold"
            fontSize={48}
          >
            Get in touch
          </ThemeHeader>

          <ContactItem img={mail}>contact@hw-sol.com</ContactItem>
          <ContactItem img={phone}>+92 332 1990 522</ContactItem>
          <ContactItem img={location}>
            Plot # 12 D, Suit # 201, Stadium lane, DHA Phase V, Karachi
          </ContactItem>
          <div className="social-link">
            <img
              className="contact-item-icon"
              src={facebook}
              style={{ marginRight: "1.5em", marginTop: "1em" }}
            />
            <img className="contact-item-icon" src={twitter} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
