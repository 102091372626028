import React from 'react'
import Header from '../Header/Header'
import "./ThemeHeader.css"

const ThemeHeader = ({children, ...restProps}) => {
    return (
        <Header customClass="theme-header" fontWeight="bold" fontSize={48} {...restProps} >{children}</Header>
    )
}

export default ThemeHeader
