import React from "react";
import Header from "../Header/Header";
import Paragraph from "../Paragraph/Paragraph";
import "./ServiceCard.css";

const ServiceCard = ({img, heading, content}) => {
  return (
    <div className="serviceCard">
      <img src={img} className="service-card-img" />
      <Header fontWeight="bold" fontSize={18} >{heading}</Header>
      <Paragraph fontSize={13} >
        {content}
      </Paragraph>
    </div>
  );
};

export default ServiceCard;
