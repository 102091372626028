import React from "react";
import "./Paragraph.css"

var fontWeightComputation = (weight) => {
  var value = 300;
  switch (weight) {
    case "light":
      value = 300;
      break;
    case "regular":
      value = 400;
      break;
    case "medium":
      value = 500;
      break;
    case "semi-bold":
      value = 600;
      break;
    case "bold":
      value = 700;
      break;
    default:
      break;
  }
  return value;
};

const Paragraph = (props) => {
  var {
    children: content = "Heading",
    fontSize = 16,
    color = "var(--color-para)",
    fontWeight = "regular",
    style = {},
    className = "",
    ...restProps
  } = props;
  var fontWeightValue = fontWeightComputation(fontWeight);
  var fontSizeValue = fontSize / 10;
  return (
    <div
      className={`paraStyle ${className}`}
      style={{
        fontSize: `${fontSizeValue}em`,
        color,
        fontWeight: fontWeightValue,
        ...style,
      }}
      {...restProps}
    >
      {content}
    </div>
  );
};

export default Paragraph;
