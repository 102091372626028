import React, { useState } from "react";
import Header from "../Header/Header";
import Paragraph from "../Paragraph/Paragraph";
import "./ProjectCard.css";
import ThemeButtom from "./../ThemeButton/ThemeButton";
import TransitionsModal from "../Modal/Modal";

const ProjectCard = ({ heading, para1, para2 }) => {
  var [open, setOpen] = useState(false);
  var handleClose = () => setOpen(false);
  return (
    <div className="projectCard">
      <TransitionsModal open={open} handleClose={handleClose}>
        <Header fontWeight="bold" style={{marginBottom: "0.5em"}} >{heading}</Header>
        <Paragraph style={{marginBottom: "1em"}} fontSize={14} >
          {para1}
        </Paragraph>
        <Paragraph fontSize={14} >
          {para2}
        </Paragraph>
      </TransitionsModal>
      <Header fontWeight="bold" fontSize={18}>
        {heading}
      </Header>
      <Paragraph style={{ marginBottom: "2em" }} fontSize={13}>
      {`${para1?.substring(0, 100)}...`}
      </Paragraph>
      <ThemeButtom onClick={() => setOpen(true)}>Read More</ThemeButtom>
    </div>
  );
};

export default ProjectCard;
