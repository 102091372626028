import React from 'react'
import Copyright from '../../Components/Copyright/Copyright'
import Footer from '../../Components/Footer/Footer'
import Projects from '../../Components/Projects/Projects'
import "./ProjectsPage.css"

const ProjectsPage = () => {
    return (
        <div className="projects-page">
            <Projects forPage={true} />
            <Footer/>
            <Copyright/>
        </div>
    )
}

export default ProjectsPage
