import React from "react";
import Paragraph from "../Paragraph/Paragraph";
import "./Button.css";

const Button = (props) => {
  var {
    children,
    fontSize = 12,
    backgroundColor = "#4287f5",
    color = "black",
    fontWeight = "regular",
    style,
    className = "",
    ...restProps
  } = props;
  return (
    <button
      {...restProps}
      style={{ ...style, background: backgroundColor }}
      className={`button-container ${className}`}
    >
      <Paragraph
        color={color}
        // style={{ letterSpacing: "0.2rem" }}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {children}
      </Paragraph>
    </button>
  );
};

export default Button;
