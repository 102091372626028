import "./App.css";
import Container from "./Components/Container/Container";
import { Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import ProjectsPage from "./Pages/ProjectsPage/ProjectsPage";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={ProjectsPage} path="/projects" />
      </Switch>
    </>
  );
}

export default App;
