import React from "react";
import Container from "../Container/Container";
import SectionHeading from "../SectionHeading/SectionHeading";
import "./Clients.css";
import client2 from "./../../assets/client2.png"
import client3 from "./../../assets/client3.png"
import client4 from "./../../assets/client4.png"
import client1 from "./../../assets/client1.png"
import ThemeHeader from "../ThemeHeader/ThemeHeader";

const Clients = () => {
  return (
    <div className="clients" id="clients">
      <Container>
      <div className="clients-header">
          <SectionHeading>Our Clients</SectionHeading>
          <ThemeHeader style={{marginBottom: "1em"}} fontWeight="bold" fontSize={48}>
            Our Satisfied Customers
          </ThemeHeader>
        </div>
        <div className="clients-list">
            <img className="client-img" src={client3} />
            <img className="client-img" src={client2} />
            <img className="client-img" src={client1} />
            <img className="client-img" src={client4} />
        </div>
      </Container>
    </div>
  );
};

export default Clients;
