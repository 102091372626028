import React from "react";
import Paragraph from "./../Paragraph/Paragraph";
import "./Copyright.css";

const Copyright = () => {
  return (
    <div className="copyright center">
      <Paragraph style={{textAlign: "center"}} fontSize={12} fontWeight="bold">
        Copyright ©2021 H&W_SOLUTION Corporation. All Rights Reserved
      </Paragraph>
    </div>
  );
};

export default Copyright;
