import React from "react";
import "./Slider.css";
import Header from "./../Header/Header";
import Arrow from "./../../assets/arrow-right.png";

const Backdrop = ({ navbar }) => (
  <div className="backdrop" style={{ opacity: navbar ? "0.4" : "0" }}></div>
);

const SliderListItem = ({ children, to, setNavbar }) => (
  <div className="slider-list-item">
    <a href={`#${to}`}>
      <Header
        onClick={() => setNavbar(false)}
        fontWeight="regular"
        fontSize={16}
      >
        {children}
      </Header>
    </a>
  </div>
);

const Slider = ({ setNavbar, navbar }) => {
  return (
    <>
      <div
        style={{
          transition: "transform 250ms ease-in-out",
          transform: navbar ? "scaleX(1)" : "scaleX(0)",
        }}
        className="slider"
      >
        <div className="slider-header">
          <img
            src={Arrow}
            onClick={() => setNavbar(false)}
            className="arrow-right"
          />
        </div>
        <div className="slider-list">
          <SliderListItem setNavbar={setNavbar} to="services">
            Services
          </SliderListItem>
          <SliderListItem setNavbar={setNavbar} to="about">
            About
          </SliderListItem>
          <SliderListItem setNavbar={setNavbar} to="projects">
            Projects
          </SliderListItem>
          <SliderListItem setNavbar={setNavbar} to="clients">
            Clients
          </SliderListItem>
          <SliderListItem setNavbar={setNavbar} to="footer">
            Contact
          </SliderListItem>
        </div>
      </div>
      <Backdrop navbar={navbar} />
    </>
  );
};

export default Slider;
