import React from 'react'
import Header from '../Header/Header'
import "./SectionHeading.css"

const SectionHeading = ({children, ...restProps}) => {
    return (
        <div {...restProps} className="sectionHeading center" style={{flexFlow: "row", justifyContent: "flex-start"}}>
            <div className="sectionHeading-line"></div>
        <Header customClass="section-header" fontWeight="medium" color="var(--color-primary)" fontSize={22} >{children}</Header>
        </div>
    )
}

export default SectionHeading
