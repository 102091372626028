import React from "react";
import "./About.css";
import Container from "./../Container/Container";
import SectionHeading from "../SectionHeading/SectionHeading";
import Paragraph from "../Paragraph/Paragraph";
import ThemeButton from "../ThemeButton/ThemeButton";
import ThemeHeader from "../ThemeHeader/ThemeHeader";

const About = () => {
  return (
    <Container>
      <div className="about" id="about">
        <div className="about-img center">
          <div className="about-img-img"></div>
        </div>
        <div className="about-content">
          <SectionHeading>About Us</SectionHeading>
          <ThemeHeader
            style={{ marginBottom: "0.5em" }}
            fontWeight="bold"
            fontSize={48}
          >
            What is H&W?
          </ThemeHeader>
          <Paragraph
            style={{ marginBottom: "1em", lineHeight: "1.5em" }}
            fontSize={14}
            fontWeight="semi-bold"
          >
            H&W Solutions is the only firm in Pakistan which is providing the
            complete engineering solutions for a wide range of engineering
            fields. From project initiation till closure, from designing till
            commissioning, from consultancy till execution, we provide a
            complete bucket of solutions depending upon the Clients’
            requirement. Our diversified team of experts ensures to provide
            up-to-date solutions to our valuable clients. Say NO to traditional
            designs and welcoming new modern technologies and trends are the key
            factors of our success and progress. Our customer-centric approach
            will compel you to knock our door whenever you require any guidance
            regarding Engineering; and our unique and cost-effective solutions
            will prove that your decision for relying on us is right. Till now,
            we have successfully completed 35+ projects across Pakistan.
          </Paragraph>
          <Paragraph
            style={{ lineHeight: "1.5em", marginBottom: "2em" }}
            fontSize={14}
            fontWeight="semi-bold"
          >
            In today’s competitive market, we believe, that the focus of top
            management should be on the development of existing business and
            exploring new horizons. Thus, we provide our consultation in almost
            every important sector in order to run the existing facilities
            smoothly and disturbance-free.
          </Paragraph>
          <ThemeButton>Download Manual</ThemeButton>
        </div>
      </div>
    </Container>
  );
};

export default About;
