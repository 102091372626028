import React from "react";
import Paragraph from "../Paragraph/Paragraph";
import "./Landing.css";
import ThemeButton from "../ThemeButton/ThemeButton";
import SectionHeading from "../SectionHeading/SectionHeading";
import Container from "../Container/Container";
import ThemeHeader from "../ThemeHeader/ThemeHeader";
import { HashLink as Link } from "react-router-hash-link";

const Landing = () => {
  return (
    <div className="landing-wrapper">
      <Container>
        <div className="landing">
          <div
            className="landing-left center"
            style={{ alignItems: "flex-start" }}
          >
            <div className="landing-left-content">
              {/* <Header>Personalize service</Header> */}
              <SectionHeading>Our Personalize Efforts For You</SectionHeading>
              <ThemeHeader fontWeight="bold" fontSize={48}>
                Answer for your every
              </ThemeHeader>
              <ThemeHeader
                style={{ marginBottom: "0.5em" }}
                fontWeight="bold"
                fontSize={48}
              >
                Engineering problem
              </ThemeHeader>
              <Paragraph
                className="landing-para"
                style={{ marginBottom: "3em" }}
              >
                H&W Solutions is the only firm in Pakistan which is providing
                the complete engineering solutions for a wide range of
                engineering fields
              </Paragraph>
              <Link to="/#about">
                <ThemeButton>Get To Know</ThemeButton>
              </Link>
            </div>
          </div>
          <div className="landing-right center">
            <div className="landing-right-img"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Landing;
