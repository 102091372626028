import React, { useState } from "react";
import Container from "../Container/Container";
import "./Navbar.css";
import logo from "./../../assets/logo.png";
import Paragraph from "../Paragraph/Paragraph";
import Slider from "../Slider/Slider";
import { HashLink as Link } from "react-router-hash-link";

const NavbarItem = ({ children = "item", to }) => (
  <Link to={`/#${to}`}  >
    <Paragraph
      color="var(--color-heading)"
      className="navItem-text"
      fontWeight="semi-bold"
      fontSize={20}
    >
      {children}
    </Paragraph>
  </Link>
);

const Navbar = () => {
  var [navbar, setNavbar] = useState(false);
  console.log(navbar);
  return (
    <div className="navbar">
      <Slider navbar={navbar} setNavbar={setNavbar} />
      <Container>
        <div className="navbar-wrapper">
          <img className="logo-img" src={logo} />
          <div className="navbar-items">
            <div className="navbar-items-list">
              <NavbarItem to="services">Services</NavbarItem>
              <NavbarItem to="about">About</NavbarItem>
              <NavbarItem to="projects">Projects</NavbarItem>
              <NavbarItem to="clients">Clients</NavbarItem>
              <NavbarItem to="footer">Contact</NavbarItem>
            </div>
            <div onClick={() => setNavbar(true)} className="ham-icon"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
