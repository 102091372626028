import React, { useState, useEffect } from "react";
import "./Projects.css";
import Container from "./../Container/Container";
import SectionHeading from "../SectionHeading/SectionHeading";
import ProjectCard from "../ProjectCard/ProjectCard";
import Button from "../Button/Button";
import ThemeHeader from "../ThemeHeader/ThemeHeader";
import { Link } from "react-router-dom";
import { firestore } from "../../Firebase";

const fetchProjects = async () => {
  var projects = [];
  var query = await firestore.collection("projects").get();
  query.forEach((project) => {
    projects.push(project.data());
  });
  return projects;
};

const Projects = ({ forPage = false }) => {
  var [projects, setProjects] = useState([]);

  useEffect(async () => {
    const projects = await fetchProjects();
    setProjects(projects);
  }, []);

  console.log(projects);
  return (
    <div className="projects" id="projects">
      <Container>
        <div className="projects-header">
          <SectionHeading>Our Projects</SectionHeading>
          <ThemeHeader
            style={{ marginBottom: "1em" }}
            fontWeight="bold"
            fontSize={48}
          >
            Our Quality Work
          </ThemeHeader>
        </div>
        <div className="projects-list">
          {!forPage
            ? projects
                .slice(0, 4)
                .map((project) => <ProjectCard {...project} />)
            : projects.map((project) => <ProjectCard {...project} />)}
        </div>

        {!forPage && (
          <div className="projects-btn center">
            <ThemeHeader
              style={{ marginBottom: "1em", textAlign: "center" }}
              fontWeight="bold"
              fontSize={32}
            >
              That's not it! We've done more than that
            </ThemeHeader>
            <Link to="/projects">
              <Button
                fontWeight="bold"
                backgroundColor="transparent"
                color="var(--color-primary)"
                className="view-more-btn"
              >
                View More Projects
              </Button>
            </Link>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Projects;
